import React from 'react'
import { Link } from '../components/Link'
import { Layout } from '../components/Layout'
import { Grid, Cell } from 'baseui/layout-grid'
import { HeadingLevel } from 'baseui/heading'
import { Heading } from '../components/Heading'
import { ParagraphLarge } from 'baseui/typography'
import { useStyletron } from 'baseui'
import { HorizontalLine } from '../components/Line'
import { LinkButton } from '../components/LinkButton'

export const LoggedOutPage = () => {
  const [css] = useStyletron()
  return (
    <>
      <Grid>
        <Cell span={[4, 6]}>
          <div className={css({ alignItems: 'start', marginBottom: '16px' })}>
            <HeadingLevel>
              <Heading>You are now logged out</Heading>
              <HorizontalLine />
            </HeadingLevel>
            <ParagraphLarge>
              Thanks for using OHShelp, to give us feedback about your experience using OHShelp,{' '}
              <Link to="/">fill out our quick feedback form.</Link>
            </ParagraphLarge>
            <LinkButton
              className={css({ marginTop: '0px', width: '50%', display: 'inline-block' })}
              to="/login"
            >
              Log back in
            </LinkButton>
          </div>
        </Cell>
      </Grid>
    </>
  )
}

const LayoutLoggedOutPage = () => (
  <Layout showNav={false} searchBarAvailable={false} backButton={false}>
    <LoggedOutPage />
  </Layout>
)

export default LayoutLoggedOutPage
